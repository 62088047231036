import React from 'react';
import styled from 'styled-components';

const Layout = styled.div`
  min-width: 100vw;
  min-height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
`;

const Left = styled.div`
  min-width: ${(props) => props.width || 160}px;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #eee;
  border-right: 1px solid #666;
`;

const LeftTop = styled.div`
  min-width: 100%;
  flex: 1;
  position: relative;
  overflow: hidden;
`;

const LeftBottom = styled.div`
  min-width: 100%;
  min-height: 64px;
`;

const Right = styled.div`
  flex: 1;
  min-height: 100%;
`;

const Modal = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`;

const MainContents = ({leftPane, leftPaneBottom, rightMap, modal}) => {
  return (
    <Layout>
      <Left>
        <LeftTop>{leftPane}</LeftTop>
        <LeftBottom>{leftPaneBottom}</LeftBottom>
      </Left>
      <Right>{rightMap}</Right>
      <Modal>{modal}</Modal>
    </Layout>
  );
};

export default MainContents;
