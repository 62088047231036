import React from 'react';
import P from 'polyline';
import {pointToLatlng, latlngEquals} from '../utils';
import {
  searchRouteByOTP,
  _updateStubGPS, // debug
} from '../api';

const RouteContext = React.createContext();

function validateRoute(route) {
  console.debug(route);
  if (!route.plan) {
    console.warn('APIエラー: planが存在しません');
    return false;
  }
  if (!route.plan.itineraries) {
    console.warn('APIエラー: itinerariesが存在しません');
    return false;
  }
  return true;
}

// TODO: デバッグ用にrouteを利用するかどうか選択可能にする
async function searchRouteVia(via, from, to, useVia = true) {
  const transits = useVia ? [from, ...via, to] : [from, to];
  console.debug('searchRouteVia: ', transits);
  let coordinates = [];
  let totalDistance = 0;
  for (let ix = 1; ix < transits.length; ++ix) {
    const json = await searchRouteByOTP(transits[ix - 1], transits[ix]);
    if (!validateRoute(json)) continue;
    const polyline = json.plan.itineraries[0].legs[0].legGeometry.points;
    const distance = json.plan.itineraries[0].legs[0].distance;
    coordinates = coordinates.concat(P.decode(polyline));
    totalDistance += distance;
  }
  coordinates = coordinates.map(pointToLatlng);
  return {coordinates, distance: totalDistance};
}

class RouteProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      route: null,
    };
  }
  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.state.route !== nextState.route ||
      this.props.children !== nextProps.children
    ) {
      return true;
    }
    if (
      latlngEquals(this.props.from, nextProps.from) &&
      latlngEquals(this.props.to, nextProps.to)
    ) {
      return false;
    } else {
      return true;
    }
  }
  componentDidMount() {
    if (this.props.from != null && this.props.to != null) {
      this._updateRoute();
    }
  }
  _updateRoute() {
    console.debug(
      'API: ルートを探索しています',
      this.props.from,
      this.props.to,
    );
    searchRouteVia(
      this.props.via,
      this.props.from,
      this.props.to,
      this.props.useVia,
    ).then(({coordinates, distance}) => {
      console.debug('API: ルートの探索が終了しました', coordinates, distance);
      // debug
      _updateStubGPS(coordinates, distance, 80);
      ///////////////////////
      const result = {
        coordinates,
        distance,
      };
      this.setState({route: result});
    });
  }
  componentDidUpdate(prevProps) {
    if (
      (prevProps.from !== this.props.from || prevProps.to !== this.props.to) &&
      this.props.from !== null &&
      this.props.to !== null
    ) {
      this._updateRoute();
    }
  }
  render() {
    const children = Array.isArray(this.props.children)
      ? this.props.children
      : [this.props.children];
    return (
      <RouteContext.Provider value={this.state.route}>
        {children.map((child, key) => (
          <RouteContext.Consumer key={key}>{child}</RouteContext.Consumer>
        ))}
      </RouteContext.Provider>
    );
  }
}

export default RouteProvider;
