import React from 'react';
import styled from 'styled-components';

const FillColor = '#fcc800';
const InvalidFillColor = '#c8c8c8';
const StrokeColor = 'rgba(0, 0, 0, 0.5)';
const InvalidStrokeColor = '#919191';
const TextColor = '#fff';

const Container = styled.div`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  display: flex;
  align-items: center;
  justify-content: center;
  border: 8px solid ${StrokeColor};
  border-radius: 16px;
  background-color: ${(props) =>
    props.isValid ? props.color : InvalidFillColor};
  color: ${TextColor};
  user-select: none;
  font-size: ${(props) => props.fontSize};
  text-align: center;

  &:hover {
    cursor: pointer;
    border-color: rgba(255, 255, 255, 0.5);
  }
`;

const NamePlate = ({
  name,
  width = '160px',
  height = '80px',
  fontSize = '24px',
  isValid = true,
  color = FillColor,
  click,
}) => {
  return (
    <Container
      width={width}
      height={height}
      color={color}
      isValid={isValid}
      fontSize={fontSize}
      onClick={click}>
      {name}
    </Container>
  );
};

export default NamePlate;
