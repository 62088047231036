import React from 'react';
import {Marker} from 'react-map-gl';
import TaxiIcon from '../taxi.png';

const VehicleMarker = props => (
  <Marker
    {...props}
    latitude={props.latlng.latitude}
    longitude={props.latlng.longitude}>
    <img
      src={props.imagePath ? props.imagePath : TaxiIcon}
      width={props.small ? 18 : 'auto'}
      height={props.small ? 18 : 'auto'}
      style={{
        opacity: props.opacity,
        transform: `translateX(-50%) translateY(-50%)`,
      }}
    />
  </Marker>
);

export default VehicleMarker;
