import React from 'react';
import UIOverlay from '../atoms/UIOverlay';
import UIButton from '../atoms/UIButton';
import styled from 'styled-components';

const Cover = styled.div`
  z-index: 10001;
  min-width: 100vw;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.4));
  pointer-events: auto;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 10002;
  position: fixed;
  top: 50vh;
  left: 50vw;
  min-width: 400px;
  min-height: 200px;
  background-color: #e4f0f5;
  border: 1px solid #3d7e9a;
  transform: translateX(-50%) translateY(-50%);
`;

const MessageContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Message = styled.div`
  text-align: center;
  font-size: 18px;
`;

const ButtonContainer = styled.div`
  margin-top: auto;
  position: relative;
  bottom: 0;
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const ButtonStyle = {
  width: '200px',
  height: '64px',
};

const ConfirmDialog = ({
  message,
  acceptLabel,
  rejectLabel,
  onAccept,
  onReject,
}) => (
  <UIOverlay style={{zIndex: 99999, display: 'block'}}>
    <Cover />
    <Container>
      <MessageContainer>
        <Message>{message}</Message>
      </MessageContainer>
      <ButtonContainer>
        <UIButton style={ButtonStyle} onClick={onAccept}>
          {acceptLabel}
        </UIButton>
        <UIButton style={ButtonStyle} onClick={onReject}>
          {rejectLabel}
        </UIButton>
      </ButtonContainer>
    </Container>
  </UIOverlay>
);

export default ConfirmDialog;
