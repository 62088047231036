import React from 'react';
import P from 'polyline';
import MainContents from '../templates/MainContents';
import Map from '../atoms/Map';
import VehicleMarker from '../atoms/VehicleMarker';
import PointerMarker from '../atoms/PointerMarker';
import PointerGroup from '../molecules/PointerGroup';
import ServiceOverlay from '../molecules/ServiceOverlay';
import UIOverlay from '../atoms/UIOverlay';
import UIButton from '../atoms/UIButton';
import NamePlate from '../atoms/NamePlate';
import Arrow from '../atoms/Arrow';
import RouteProvider from '../atoms/RouteProvider';
import TransitGroup from '../organisms/TransitGroup';
import ConfirmDialog from '../molecules/ConfirmDialog';
import {PassengerDialog} from '../molecules/PassengerDialog';
import {getPassengerFromFireStore} from '../firebase/firestore-read';
import {flatMap, latlngEquals, getDayTimeRange} from '../utils';
import markerIcon from '../marker-icon.png';

import {
  // TODO: $fetchVehicleはデバッグ用スタブ
  // $fetchVehicle as fetchVehicle,
  fetchVehicle,
  setVehicleState,
  $gps,
  fetchPassenger,
  _updateStubGPS,
} from '../api';

import {distance, StrtoBoolean, arrayEquals} from '../utils';

Array.prototype.flatMap = function(f) {
  return flatMap(f, this);
};

const IS_DEBUGGING = true;
const DEBUG_VEHICLE_ID = 'vehicle-1';
const GPS_INTERVAL = 1 * 1000;
const LATLNG_OKINAWA = {latitude: 26.334353, longitude: 127.805606};

/*
 * スタブもしくは実機(Android)側から渡されるGPSの値をコールバックに渡す
 *
 * この関数は頻繁に呼ばれるためパフォーマンスに注意すること
 **/
function gps(isUseGPSSTUB, callback) {
  if (isUseGPSSTUB) {
    return callback($gps().next().value);
  } else {
    // eslint-disable-next-line
    const gpsdata = JSON.parse(jsPassData.getGpsData());
    console.log(gpsdata);
    if (gpsdata['isGpsEnable']) {
      let position = {
        coords: {
          latitude: parseFloat(gpsdata['latitude']),
          longitude: parseFloat(gpsdata['longitude']),
        },
      };
      return callback(position);
    } else {
      console.log('index/gps: GPS信号がありません');
      return callback(null);
    }
  }
}

/*
 * GPSスタブを実機(Android)で用いるかどうかを実機側から渡されるオブジェクトから取得し返す
 *
 * 実機でない場合(オブジェクトが渡ってこない場合)はスタブを使うためtrueを渡す
 * この関数は初期化のタイミングで一度だけ呼ばれる
 **/
function getUseGPSSTUBFlagFromAndroid() {
  let isUseGPSSTUB;
  try {
    // eslint-disable-next-line
    isUseGPSSTUB = StrtoBoolean(jsPassData.getUseGPSSTUBFlag());
  } catch (e) {
    isUseGPSSTUB = true;
  }
  return isUseGPSSTUB;
}

/*
 * vehicleIdを実機(Android)側から渡されるオブジェクトから取得し返す
 *
 * 実機でない場合(オブジェクトが渡ってこない場合)はデバッグ用のidを渡す
 * この関数は初期化のタイミングで一度だけ呼ばれる
 **/
function getVehicleIdFromAndroid() {
  let vehicleId;
  try {
    // eslint-disable-next-line
    vehicleId = jsPassData.getBus_code();
  } catch (e) {
    if (IS_DEBUGGING) {
      vehicleId = DEBUG_VEHICLE_ID;
    }
  }
  return vehicleId;
}

/*
 * vehicleDataの初期値を生成して返す
 **/
function initialVehicleData() {
  return {
    vehicleState: 'undefined',
    lastUpdated: Date.now(),
  };
}

/*
 * vehicleAPIの結果が期待するフォーマットと一致するかを検証し、許容可能であればそのオブジェクトを返却する
 *
 * 一部の存在しないフィールドは初期化される
 * この関数はvehicleAPIと共に呼ばれる
 **/
function validateVehicle(json) {
  console.debug(
    '### DEBUG_LOG:FUNCTION:validateVehicle #########################',
  );
  if (!json.data) {
    console.error('index/validateVehicle: APIエラー: dataが存在しません');
    return null;
  }
  const data = json.data;
  // NOTE: operatingTimesとoperatingSessionsの長さが一致するか不明
  // const size = data.operatingSessions.length;
  // if (size !== data.operatingTimes.length) {
  //   console.error(
  //     '不正なフォーマット: operatingSessionsとoperatingTimesの長さが一致しません',
  //   );
  // }

  // if (
  //   !data.appData.vehicleState ||
  //   (data.appData.vehicleState && data.appData.vehicleState === '')
  // ) {
  //   console.log(
  //     `index/validateVehicle: 未定義フィールド: appData.vehicleState undefined`,
  //   );
  //   data.appData.vehicleState = 'undefined';
  // }

  try {
    JSON.parse(atob(data.appData.vehicleState));
  } catch (e) {
    console.log(`index/validateVehicle: 不正なフォーマット: 初期化します`, e);
    data.appData.vehicleState = btoa(JSON.stringify(initialVehicleData()));
  }

  for (let ix = 0; ix < data.operatingSessions.length; ++ix) {
    const os = data.operatingSessions[ix];
    if (os.stoppingPoints[0].location !== os.route[0].name) {
      console.log(
        `index/validateVehicle: 不正なフォーマット: 発車地点と0番目の降車地点が一致しません`,
        os,
      );
    }
  }
  return data;
}

const getPassengerColor = async (passengerUid) => {
  try {
    const now = Date.now();
    const passenger = await fetchPassenger(passengerUid);
    const bool_list = passenger.data.transports
      .map((t) => {
        return t.departureTransitTimeEarlier;
      })
      .map((time) => {
        if (time === null) {
          return;
        }
        const date = new Date(time * 1000);

        return date.getTime() < now;
      });

    const isBeforeRide = bool_list.indexOf(true) !== -1 ? true : false;

    if (isBeforeRide) {
      return '#000';
    } else {
      return '#f00';
    }
  } catch (e) {
    console.error(e);
    console.log('Passenger取得失敗 : ' + passengerUid + 'は無効なUID');
    return '#000';
  }
};

const getPassenger = async (passengerUid) => {
  try {
    const passenger = await getPassengerFromFireStore(passengerUid);
    const color = await getPassengerColor(passengerUid);
    if (passenger.name) {
      return {id: passengerUid, name: passenger.name, color: color};
    } else {
      return {id: passengerUid, name: passenger.email, color: color};
    }
  } catch {
    console.log('Passenger取得失敗 : 無効なUID');
    return {id: passengerUid, name: passengerUid, color: '#000'};
  }
};

const createPassengerNameDict = async (operatingSessions) => {
  const passengerNameDict = Object.keys(operatingSessions)
    .map((s) => {
      return operatingSessions[s].stoppingPoints;
    })
    .flatMap((s) => {
      return s;
    })
    .flatMap((stp) => {
      return [stp.pickups, stp.dropoffs];
    })
    .flatMap((p) => {
      return p;
    })
    .map((p) => {
      return p.passenger;
    })
    .filter((elem, index, self) => {
      return self.indexOf(elem) === index;
    })
    .map(getPassenger);
  return (await Promise.all(passengerNameDict)).reduce((a, v) => {
    a[v.id] = {name: v.name, color: v.color};
    return a;
  }, {});
};

/*
 * 現在時刻から次のセッションのidを計算して返す
 *
 * 引数で受け取るstate.operatingSessionsはendTimeの昇順でソートされていることが期待される
 * この関数はoperatingSessionsが更新されるタイミングで呼ばれる
 **/
function calcCurrentSessionId(state) {
  console.debug(
    '### DEBUG_LOG:FUNCTION:calcCurrentSessionId #########################',
  );
  const {operatingSessions, sessionOrders} = state;
  const now = Date.now();
  let currentSessionIndex = 0;
  for (
    currentSessionIndex;
    currentSessionIndex < sessionOrders.length;
    ++currentSessionIndex
  ) {
    // endTimeの昇順にソートされているので先頭から見ていって現在時刻より後のインデックスに合わせる
    console.debug(
      '### DEBUG_LOG:COMMENT:endTimeの昇順にソートされているので先頭から見ていって現在時刻より後のインデックスに合わせる #########################',
    );
    if (operatingSessions[sessionOrders[currentSessionIndex]].endTime > now) {
      break;
    }
  }
  console.debug('calcCurrentSessionId: ', sessionOrders[currentSessionIndex]);
  return sessionOrders[currentSessionIndex];
}

/*
 * vehicleAPIによりoperatingSessionsを取得し、扱いやすいように加工したオブジェクトをPromiseで返却する
 *
 * operatingSessionsの優先順位はここで付加されるendTimeによって決定され、ソートされる
 * この関数は特定条件下以外にも定期的に呼ばれる
 **/
function getOperatingSessions(vehicleId) {
  return fetchVehicle(vehicleId, Date.now() + 1000).then(async (json) => {
    const vehicle = validateVehicle(json);
    if (!vehicle) {
      return;
    }

    const vehicleData = JSON.parse(atob(vehicle.appData.vehicleState));

    console.debug(
      '### DEBUG_LOG:getOperatingSessions:DBからとってきたvehicleData #########################',
    );
    console.log(vehicleData);

    // 直近のoperatingSessionsを特定するため、endTimeを付加
    let operatingSessions = vehicle.operatingSessions.map((os, ix) => {
      if (os.vehicle !== vehicleId) {
        console.warn(
          `index/getOperatingSessions: 不正なフォーマット: 指定したvehicleId=${vehicleId}とoperatingSessions[${ix}]のvehicleIdが一致しません`,
        );
      }
      console.debug(
        '### DEBUG_LOG:COMMENT:直近のoperatingSessionsを特定するため、endTimeを付加 #########################',
      );
      return {
        oid: os.oid,
        route: os.route,
        stoppingPoints: os.stoppingPoints,
        transports: os.transports.map((t) => t.id),
        endTime: os.stoppingPoints[os.stoppingPoints.length - 1].transitTime
          .split('~')
          .map((s) => new Date(parseInt(s) * 1000))[1],
      };
    });
    const [dayStart, dayEnd] = getDayTimeRange();
    // 当日のセッション以外をフィルタリング
    operatingSessions = operatingSessions.filter(
      (os) => dayStart <= os.endTime && os.endTime <= dayEnd,
    );
    // endTimeで昇順ソート(先頭が最初に終わるセッション)
    operatingSessions.sort((lhs, rhs) => {
      return lhs.endTime - rhs.endTime;
    });

    // operatingSessionsの順番とoidを対応付け
    const sessionOrders = operatingSessions.map((os) => os.oid);

    // operatingSessionsはoidで引けるようにする
    let _operatingSessions = {};
    for (const os of operatingSessions) {
      _operatingSessions[os.oid] = os;
    }
    operatingSessions = _operatingSessions;

    const passengerNameDict = await createPassengerNameDict(operatingSessions);
    console.debug(
      '### DEBUG_LOG:COMMENT:getOperatingSessions抜けるときvehicleData #########################',
    );
    console.log(vehicle);
    console.log(operatingSessions);

    return {
      operatingSessions,
      sessionOrders,
      vehicleData,
      passengerNameDict,
    };
  });
}

/*
 * operatingSessionに含まれるrouteからstoppingPointの緯度経度を取得する
 *
 * sessionにはrouteが存在し、routeにはstopの緯度経度が含まれていることが期待される
 * この関数はrender時に呼ばれる
 **/
function getStopCoordinateFromRoute(session, stop) {
  //console.debug(    '### DEBUG_LOG:FUNCTION:getStopCoordinateFromRoute #########################',  );
  return session.route.find((r) => r.name === stop.location);
}

/*
 * stoppingPoints間のrouteをoperatingSessionから切り出す
 *
 * 同名のstoppingPointが複数含まれる可能性があるため、stoppingPointに関してはインデックスを受け取る
 * この関数はrender時に呼ばれる
 **/
function sliceRouteBetweenStoppingPoints(session, fromStopIdx, toStopIdx) {
  //console.debug(    '### DEBUG_LOG:FUNCTION:sliceRouteBetweenStoppingPoints #########################',  );
  let routeIdx;
  let stopIdx = 0;
  let fromRouteIdx = null;
  let toRouteIdx = null;
  for (routeIdx = 0; routeIdx < session.route.length; ++routeIdx) {
    if (
      session.route[routeIdx].name === session.stoppingPoints[stopIdx].location
    ) {
      if (stopIdx === fromStopIdx) {
        fromRouteIdx = routeIdx;
      } else if (stopIdx === toStopIdx) {
        toRouteIdx = routeIdx;
      }
      stopIdx += 1;
    }
  }
  return session.route.slice(fromRouteIdx + 1, toRouteIdx);
}

function validLatLng(latlng) {
  return (
    latlng != null &&
    latlng.latitude &&
    !Number.isNaN(latlng.latitude) &&
    latlng.longitude &&
    !Number.isNaN(latlng.longitude)
  );
}

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // GPSの位置 : LatLng
      gpsPosition: null,
      // JS側で取得したGPSの位置 : LatLng,
      jsGpsPosition: null,
      // オペレーティングセッション : {[oid]: operatingSession}
      operatingSessions: {},
      // オペレーティングセッションの終了昇順 : Array<oid>
      sessionOrders: [],
      // 現在処理中のオペレーティングセッションのoid : oid
      currentSessionId: null,
      // 左ペインで閲覧中のオペレーティングセッションのuid : oid
      viewingSessionId: null,
      // operatingSession.stoppingPointsにおける次の目的地(降車地点)のインデックス : number
      nextStoppingPointIndex: 0,
      // 永続化するステート(再起動対策) : Object
      vehicleData: null,
      // 乗降客一覧
      passengerNameDict: null,
      // 左ペインで選択されている目的地のインデックス : number
      selectedStoppingPointIndex: null,
      // 目的地に到着しているかどうか : boolean
      isArrived: false,
      // 目的地に到着しているかどうか(経路アニメ) : boolean
      isArrivedOnRoute: false,
      // セッション開始直前に最後に居た地点 : LatLng
      sessionStartPosition: null,
      // 確認ダイアログ情報 : ConfirmState
      confirm: null,
      // operatingSessionsの更新に失敗したかどうか : boolean
      failedUpdateOperatingSessions: false,

      // 経路探索にrouteを利用するかどうか : boolean
      useVia: false,
    };
    this.transitsContainerRef = React.createRef();
    this.isUseGPSSTUB = false;
    this.useJSGPS = false;
    this.isDepartureButtonPressed = false;
    this.isArrivedButtonPressed = false;
  }

  componentDidMount() {
    this.isUseGPSSTUB = getUseGPSSTUBFlagFromAndroid();
    this._onSetGPSSTUBFlag(this.isUseGPSSTUB);
    this.vehicleId = getVehicleIdFromAndroid();
    this._onSetVehicleId(this.vehicleId).then((upstate) =>
      this.setState(upstate, () => {
        setInterval(this._updateGPS.bind(this), GPS_INTERVAL);
        this.forceUpdate();
      }),
    );
    setInterval(this._updateOperatingSessions.bind(this), 60000);
  }

  componentDidUpdate(_, prevState) {
    this.isDepartureButtonPressed = false;
    this.isArrivedButtonPressed = false;
    if (
      this.state.nextStoppingPointIndex !== prevState.nextStoppingPointIndex
    ) {
      this._scrollToCurrentStopPlate();
    }
  }

  _scrollToCurrentStopPlate() {
    const elem = this.transitsContainerRef.current;
    if (elem) {
      console.log(
        '## DEBUG_index/_scrollToCurrentStopPlate: 現在の降車地点の表示までスクロールします',
      );
      const {nextStoppingPointIndex} = this.state;
      elem.scrollTop = Math.max((nextStoppingPointIndex - 1) * 176, 0);
    }
  }

  _saveVehicleData(
    {
      vehicleState,
      currentSessionId,
      nextStoppingPointIndex,
      lastDepartedStopIndex,
      lastPosition,
    },
    clear = false,
  ) {
    let vehicleData;
    if (clear) {
      vehicleData = {
        vehicleState,
        currentSessionId,
        nextStoppingPointIndex,
        lastDepartedStopIndex,
        lastPosition,
      };
      console.log(
        `### DEBUG_index/_saveVehicleData: vehicleDataを上書きします`,
        vehicleData,
      );
    } else {
      vehicleData = {...this.state.vehicleData};
      console.log(
        `### DEBUG_index/_saveVehicleData: vehicleDataを保存します`,
        vehicleData,
      );
      if (vehicleState != null) {
        vehicleData.vehicleState = vehicleState;
      }
      if (currentSessionId != null) {
        vehicleData.currentSessionId = currentSessionId;
      }
      if (lastDepartedStopIndex != null) {
        vehicleData.lastDepartedStopIndex = lastDepartedStopIndex;
      }
      if (nextStoppingPointIndex != null) {
        vehicleData.nextStoppingPointIndex = nextStoppingPointIndex;
      }
      if (lastPosition != null) {
        vehicleData.lastPosition = lastPosition;
      }
      vehicleData.lastUpdated = Date.now();
    }

    const encoded = btoa(JSON.stringify(vehicleData));

    try {
      // eslint-disable-next-line
      jsPassData.setVehicleData(encoded);
      return Promise.resolve(vehicleData);
    } catch (e) {
      console.log(e);
      if (this.useJSGPS) {
        localStorage.setItem('vehicleData', encoded);
        return Promise.resolve(vehicleData);
      } else {
        return setVehicleState(this.vehicleId, {
          vehicleState: encoded,
        }).then(() => {
          return vehicleData;
        });
      }
    }
  }

  _onSetGPSSTUBFlag(isUseGPSSTUB) {
    this.isUseGPSSTUB = isUseGPSSTUB;
    if (isUseGPSSTUB) {
      navigator.geolocation.watchPosition(this._updateJSGPS.bind(this));
    }
  }

  _onSetVehicleId(vehicleId) {
    console.debug(
      `### DEBUG_index/_onSetVehicleId: 初期化: vehicleId=${vehicleId}が設定されました`,
    );
    this.vehicleId = vehicleId;
    return getOperatingSessions(vehicleId).then((state) => {
      if (!state) {
        console.error(
          'index/_onSetVehicleId: エラー: OperatingSessionsの取得に失敗しました',
        );
        return;
      }
      console.log(
        '### DEBUG_LOG:COMMENT:取得成功(初期) #########################',
        state,
      );

      // Androidの場合はローカルのステートを利用する
      try {
        let vehicleDataEncoded;
        // eslint-disable-next-line
        vehicleDataEncoded = jsPassData.getVehicleData();
        console.debug(
          '### DEBUG_LOG:COMMENT:Androidからステートをロード #########################',
          vehicleDataEncoded,
        );
        try {
          const vehicleData = JSON.parse(atob(vehicleDataEncoded));
          state.vehicleData = vehicleData;
          console.debug(
            '### DEBUG_LOG:COMMENT:Androidからロードしたステートをパース #########################',
            vehicleData,
          );
        } catch (e) {
          console.debug(
            '### DEBUG_LOG:COMMENT:Androidローカルのステートのパースに失敗したので初期値を利用 #########################',
          );
          state.vehicleData = initialVehicleData();
        }
      } catch (e) {
        console.debug(
          '### DEBUG_LOG:COMMENT:Androidローカルのステートのロードに失敗しました #########################',
          e,
        );
        const local = localStorage.getItem('vehicleData');
        if (local) {
          state.vehicleData = JSON.parse(atob(local));
          console.debug(
            '### DEBUG_LOG:COMMENT:localStorageの中にvehicleDataを発見したので利用します #########################',
            state.vehicleData,
          );
        }
      }

      // vehicleDataの更新時刻から1日が経過していた場合は利用しないようにするため、
      // 受け取ったvehicleDataを初期値に戻す
      const DAYTIME = 24 * 60 * 60 * 1000;
      const timeDiff = state.vehicleData.lastUpdated
        ? Date.now() - state.vehicleData.lastUpdated
        : DAYTIME;
      if (timeDiff >= DAYTIME) {
        state.vehicleData = initialVehicleData();
      }

      let currentSessionId;
      let byTime = false;
      if (state.vehicleData.currentSessionId != null) {
        // DBの最後のcurrentSessionIdと一致するインデックスに復帰
        currentSessionId = state.vehicleData.currentSessionId;
        console.debug(
          '### DEBUG_LOG:COMMENT:最後のvehicleDataのcurrentSessionIdに復帰 #########################',
          currentSessionId,
        );
        if (!state.operatingSessions[currentSessionId]) {
          console.debug(
            '### DEBUG_LOG:COMMENT:存在しないoperatingSessionsを指しているため現在時刻から決定します #########################',
          );
          currentSessionId = calcCurrentSessionId(state);
          byTime = true;
        }
      } else {
        // 現在時刻からcurrentSessionIndexを決定
        console.debug(
          '### DEBUG_LOG:COMMENT:現在時刻からcurrentSessionIdを決定 #########################',
        );
        currentSessionId = calcCurrentSessionId(state);
        byTime = true;
      }
      // operatingSessionsにoid === currentSessionIdであるセッションが存在しない場合
      if (!state.operatingSessions[currentSessionId]) {
        currentSessionId = null;
      }

      let nextStoppingPointIndex;
      if (state.operatingSessions[currentSessionId]) {
        if (state.vehicleData.nextStoppingPointIndex != null || !byTime) {
          // DBの最後のnextStoppingPointIndexに復帰
          console.debug(
            '### DEBUG_LOG:COMMENT:最後のvehicleDataのnextStoppingPointIndexに復帰 #########################',
          );
          nextStoppingPointIndex = state.vehicleData.nextStoppingPointIndex;
        } else {
          // 現在時刻からnextStoppingPointIndexを決定
          console.debug(
            '### DEBUG_LOG:COMMENT:現在時刻からnextStoppingPointIndexを決定 #########################',
          );
          const currentSessionStoppingPointsEndTimes = state.operatingSessions[
            currentSessionId
          ].stoppingPoints
            .map((stp) => {
              return stp.transitTime;
            })
            .map((t) => {
              return t.split('~')[0];
            })
            .map((s) => new Date(parseInt(s) * 1000));
          nextStoppingPointIndex = currentSessionStoppingPointsEndTimes.length;
          const now = Date.now();
          for (
            let ix = 0;
            ix < currentSessionStoppingPointsEndTimes.length;
            ++ix
          ) {
            if (now < currentSessionStoppingPointsEndTimes[ix].getTime()) {
              nextStoppingPointIndex = ix;
              break;
            }
          }
        }
      }

      const vehicleData = {
        ...state.vehicleData,
        vehicleState: state.vehicleData.vehicleState
          ? state.vehicleData.vehicleState
          : 'undefined',
        currentSessionId,
        nextStoppingPointIndex,
      };
      const lastPosition = vehicleData.lastPosition
        ? vehicleData.lastPosition
        : null;

      this._saveVehicleData(vehicleData);

      return {
        ...state,
        vehicleData,
        currentSessionId,
        viewingSessionId: currentSessionId,
        nextStoppingPointIndex,
        gpsPosition: lastPosition,
        jsGpsPosition: lastPosition,
        sessionStartPosition: lastPosition,
      };
    });
  }

  _getCurrentSessionIndex(currentSessionId, sessionOrders) {
    console.log(currentSessionId, sessionOrders);
    const currentSessionIndex = sessionOrders.findIndex(
      (oid) => oid === currentSessionId,
    );
    if (currentSessionIndex === -1) {
      return sessionOrders.length;
    } else {
      return currentSessionIndex;
    }
  }

  _onChangeArriveStateOnRoute(isArrivedOnRoute) {
    console.debug(
      '### DEBUG_LOG:CLASS-Index:FUNCTION:_onChangeArriveStateOnRoute #########################',
    );
    this.setState({
      isArrivedOnRoute,
    });
  }

  _onClickGotoDeparturePointButton() {
    const {
      gpsPosition,
      currentSessionId,
      nextStoppingPointIndex,
      vehicleData,
    } = this.state;
    if (
      vehicleData &&
      vehicleData.vehicleState === 'undefined' &&
      validLatLng(gpsPosition)
    ) {
      this._saveVehicleData({
        vehicleState: 'driving',
        currentSessionId,
        lastDepartedStopIndex: -1,
        nextStoppingPointIndex,
      }).then((newVehicleData) => {
        console.log(
          'index/onArrived: 降車地点: vehicleState <- waiting',
          newVehicleData,
        );
        this.setState({
          vehicleData: newVehicleData,
          sessionStartPosition: gpsPosition,
        });
      });
    }
  }

  _onClickDepartureButton() {
    if (!this.isDepartureButtonPressed) {
      console.debug(
        '### DEBUG_LOG:CLASS-Index:FUNCTION:_onClickDepartureButton #########################',
      );
      const {nextStoppingPointIndex, currentSessionId} = this.state;
      this._saveVehicleData({
        vehicleState: 'driving',
        currentSessionId,
        lastDepartedStopIndex: nextStoppingPointIndex - 1,
        nextStoppingPointIndex: nextStoppingPointIndex,
      }).then((newVehicleData) => {
        console.log(
          '### DEBUG_LOG:COMMENT:ドライビングモードに変更 index/onClickDeparture: 出発: vehicleState <- driving',
          newVehicleData,
        );
        this.setState({
          vehicleData: newVehicleData,
        });
      });
    } else {
      console.log(
        '### DEBUG_LOG:COMMENT:出発ボタンがすでに押されているため処理しない',
      );
    }
  }

  _onClickArrivedButton(nextStoppingPointIndex = null) {
    if (!this.isArrivedButtonPressed) {
      console.debug(
        '### DEBUG_LOG:CLASS-Index:FUNCTION:_onClickArrivedButton #########################',
      );
      if (nextStoppingPointIndex == null) {
        nextStoppingPointIndex = this.state.nextStoppingPointIndex;
      }
      let {
        operatingSessions,
        currentSessionId,
        sessionOrders,
        vehicleData,
        gpsPosition,
      } = this.state;

      // 最新のvehicleを取得して更新
      console.debug(
        '### DEBUG_LOG:COMMENT:最新のvehicleを取得して更新 #########################',
      );
      getOperatingSessions(this.vehicleId)
        .then((upstate) => {
          if (!upstate) {
            console.error(
              'index/onArrived: エラー: OperatingSessionsの取得に失敗しました',
            );
            return;
          }

          this.setState({
            operatingSessions: upstate.operatingSessions,
            sessionOrders: upstate.sessionOrders,
            passengerNameDict: upstate.passengerNameDict,
          });
        })
        .catch((e) => {
          this.setState({
            failedUpdateOperatingSessions: true,
          });
        });

      // operatingSessionsの更新に失敗しても運行は続行するため、まずは現在のoperatingSessionsから次の目的地を決定

      // operatingSessionのUUIDからcurrentSessionIndexを決定
      console.debug(
        '### DEBUG_LOG:COMMENT:現在のセッションのUUIDからcurrentSessionIndexを取得 #########################',
      );
      const currentSessionIndex = this._getCurrentSessionIndex(
        currentSessionId,
        sessionOrders,
      );

      let vehicleState;
      console.debug('index/onArrived: 降車地点: 停車します');
      // 降車地点(nextStoppingPointIndex)を進める
      console.debug(
        '### DEBUG_LOG:COMMENT:降車地点(nextStoppingPointIndex)を進める #########################',
      );
      nextStoppingPointIndex += 1;
      console.debug(
        '### DEBUG_LOG:COMMENT:waitingに変更 #########################',
      );
      vehicleState = 'waiting';
      console.log(currentSessionId);
      if (
        nextStoppingPointIndex >=
        operatingSessions[currentSessionId].stoppingPoints.length
      ) {
        // 最後の降車地点に到着した場合は、currentSessionIndexを進める
        console.debug(
          'index/onArrived: 最後の降車地点: 次のセッションへ移行します',
          nextStoppingPointIndex,
        );
        currentSessionId = sessionOrders[currentSessionIndex + 1];
        nextStoppingPointIndex = 0;
        console.debug(
          '### DEBUG_LOG:COMMENT:vehicleData.vehicleState = undefinedに変更 #########################',
        );
        vehicleState = 'undefined';
        this.setState({
          sessionStartPosition: gpsPosition,
        });
      }

      console.debug(
        '### DEBUG_LOG:CLASS-Index:VARIABLE:vehicleData.vehicleState=',
        vehicleState,
        ' #########################',
      );

      // vehicleDataを保存し、ローカルのstateをDBと同期する
      this._saveVehicleData({
        vehicleState,
        currentSessionId,
        nextStoppingPointIndex,
        sessionStartPosition: gpsPosition,
      }).then((newVehicleData) => {
        console.log(
          'index/onArrived: 降車地点: vehicleState <- waiting',
          newVehicleData,
        );
        this.setState({
          vehicleData: newVehicleData,
        });
      });

      // isArrived = falseとなるのと同時にvehicleStateを更新したい
      vehicleData.vehicleState = vehicleState;
      this.setState({
        currentSessionId,
        viewingSessionId: currentSessionId,
        nextStoppingPointIndex,
        vehicleData,
        isArrived: false,
        isArrivedOnRoute: false,
        sessionStartPosition: gpsPosition,
      });
    } else {
      console.log(
        '### DEBUG_LOG:COMMENT:到着ボタンがすでに押されているため処理しない',
      );
    }
  }

  _changeCurrentSessionToViewing() {
    const {viewingSessionId} = this.state;
    const confirm = {
      message: '本当にセッションを切り替えますか？',
      onAccept: () => {
        this._saveVehicleData({
          vehicleState: 'undefined',
          currentSessionId: viewingSessionId,
          lastDepartedStopIndex: -1,
          nextStoppingPointIndex: 0,
        }).then((newVehicleData) => {
          this.setState({
            currentSessionId: viewingSessionId,
            nextStoppingPointIndex: 0,
            vehicleData: newVehicleData,
          });
        });
      },
    };
    this.setState({confirm});
  }

  _changeNextStoppingPointToViewing() {
    const {viewingSessionId, selectedStoppingPointIndex} = this.state;
    const confirm = {
      message: '本当に目的地を変更しますか？',
      onAccept: () => {
        this._saveVehicleData({
          vehicleState: 'undefined',
          currentSessionId: viewingSessionId,
          lastDepartedStopIndex: selectedStoppingPointIndex - 1,
          nextStoppingPointIndex: selectedStoppingPointIndex,
        }).then((newVehicleData) => {
          this.setState({
            selectedStoppingPointIndex: null,
            currentSessionId: viewingSessionId,
            nextStoppingPointIndex: selectedStoppingPointIndex,
            vehicleData: newVehicleData,
          });
        });
      },
    };
    this.setState({
      confirm,
    });
  }

  _markArrivedSelectedStop() {
    const {
      operatingSessions,
      currentSessionId,
      selectedStoppingPointIndex,
    } = this.state;
    const name =
      operatingSessions[currentSessionId].stoppingPoints[
        selectedStoppingPointIndex
      ].location;
    const confirm = {
      message: `本当に${name}に到着済みにしますか？`,
      onAccept: () => {
        this._onClickArrivedButton(selectedStoppingPointIndex);
        this.setState({
          selectedStoppingPointIndex: null,
        });
      },
    };
    this.setState({confirm});
  }

  _selectSession(viewingSessionId) {
    console.debug(
      '### DEBUG_LOG:CLASS-Index:FUNCTION:_selectSession #########################',
      viewingSessionId,
    );
    this.setState({
      viewingSessionId,
    });
  }

  _onCurrentSession() {
    const {currentSessionId} = this.state;
    console.debug(
      '### DEBUG_LOG:CLASS-Index:FUNCTION:_onCurrentSession #########################',
    );
    console.debug(
      'index/_onCurrentSession: 現在のセッションを表示',
      currentSessionId,
    );
    this._selectSession(currentSessionId);
  }

  _onNextSession() {
    console.debug(
      '### DEBUG_LOG:CLASS-Index:FUNCTION:_onNextSession #########################',
    );
    console.debug('index/_onCurrentSession: 次のセッションを表示');
    const {sessionOrders, viewingSessionId} = this.state;
    console.log(sessionOrders);
    const currentSessionIndex = this._getCurrentSessionIndex(
      viewingSessionId,
      sessionOrders,
    );
    if (currentSessionIndex !== -1) {
      const nextSessionId = sessionOrders[currentSessionIndex + 1];
      this._selectSession(nextSessionId);
    }
  }

  _onPrevSession() {
    console.debug(
      '### DEBUG_LOG:CLASS-Index:FUNCTION:_onPrevSession #########################',
    );
    console.debug('index/_onCurrentSession: 前のセッションを表示');
    const {sessionOrders, viewingSessionId} = this.state;
    console.log(viewingSessionId);
    let currentSessionIndex = this._getCurrentSessionIndex(
      viewingSessionId,
      sessionOrders,
    );
    console.log(currentSessionIndex);
    if (0 < currentSessionIndex) {
      const previousSessionId = sessionOrders[currentSessionIndex - 1];
      this._selectSession(previousSessionId);
    }
  }

  _updateJSGPS(position) {
    console.debug(
      '### DEBUG_LOG:CLASS-Index:FUNCTION:_updateJSGPS #########################',
    );
    this.useJSGPS = true;
    this.setState({
      jsGpsPosition: position.coords,
    });
  }

  _updateGPS() {
    console.debug(
      '### DEBUG_LOG:CLASS-Index:FUNCTION:_updateGPS #########################',
    );
    gps(this.isUseGPSSTUB, (position) => {
      if (position != null) {
        let {
          gpsPosition,
          operatingSessions,
          currentSessionId,
          nextStoppingPointIndex,
          vehicleData,
        } = this.state;

        if (vehicleData && vehicleData.vehicleState) {
          console.debug(
            '### DEBUG_LOG:CLASS-Index:VARIABLE:vehicleData.vehicleState=',
            vehicleData.vehicleState,
            ' #########################',
          );
        }

        if (
          gpsPosition &&
          position.coords &&
          vehicleData &&
          vehicleData.vehicleState === 'waiting' &&
          operatingSessions &&
          operatingSessions[currentSessionId] &&
          distance(gpsPosition, position.coords) > 0.0000001
        ) {
          // vehicleState === 'waiting'時に移動した場合
          console.debug(
            '### DEBUG_LOG:COMMENT:vehicleState === waiting 時に移動した場合 #########################',
          );
        }

        const currentSession = operatingSessions
          ? operatingSessions[currentSessionId]
          : null;
        // GPSの値が正常である場合
        if (validLatLng(position.coords)) {
          gpsPosition = position.coords;
          // 最後の位置をvehicleDataに記録
          this._saveVehicleData({lastPosition: gpsPosition});
          if (this.state.sessionStartPosition == null) {
            // セッション開始位置がnullの場合は現在位置を代入
            this._saveVehicleData({
              sessionStartPosition: gpsPosition,
            });
            this.setState({
              sessionStartPosition: gpsPosition,
            });
          }
        }

        const target =
          currentSession &&
          currentSession.stoppingPoints &&
          currentSession.stoppingPoints[nextStoppingPointIndex]
            ? getStopCoordinateFromRoute(
                currentSession,
                currentSession.stoppingPoints[nextStoppingPointIndex],
              )
            : null;
        let isArrived = false;
        // 約100m以内(緯度経度でユークリッド距離を取っている)
        // 調整したい場合は次の行のコメントを外し、ログを見ながら決める
        if (target) {
          console.log(distance(gpsPosition, target));
          if (target && distance(gpsPosition, target) <= 0.00001) {
            isArrived = true;
          }
        }

        this.setState({
          gpsPosition,
          isArrived,
        });
      }
    });
  }

  _updateOperatingSessions(isForce = false) {
    console.debug(
      '### DEBUG_LOG:CLASS-Index:FUNCTION:_updateOperatingSessions #########################',
    );
    const {
      operatingSessions,
      sessionOrders,
      currentSessionId,
      viewingSessionId,
      nextStoppingPointIndex,
      vehicleData,
      passengerNameDict,
      failedUpdateOperatingSessions,
    } = this.state;
    let _operatingSessions = operatingSessions;
    let _sessionOrders = sessionOrders;
    let _currentSessionId = currentSessionId;
    let _viewingSessionId = viewingSessionId;
    let _nextStoppingPointIndex = nextStoppingPointIndex;
    let _passengerNameDict = passengerNameDict;
    if (
      isForce ||
      (vehicleData &&
        (vehicleData.vehicleState === 'waiting' ||
          vehicleData.vehicleState === 'undefined')) ||
      failedUpdateOperatingSessions
    ) {
      console.log('index/_updateOperatingSessions: 更新');
      // 最新のvehicleを取得して更新
      console.debug('### DEBUG_LOG:COMMENT: #########################');
      getOperatingSessions(this.vehicleId).then((upstate) => {
        if (!upstate) {
          console.error(
            'index/_updateOperatingSessions: エラー: OperatingSessionsの取得に失敗しました',
          );
          return;
        }

        console.log(
          '### DEBUG_LOG:COMMENT: 現在の状態#########################',
          this.state,
        );
        console.log(
          '### DEBUG_LOG:COMMENT: 取得したデータ#########################',
          upstate,
        );

        // セッションが増えた場合、現在時刻からセッションIDを算出
        if (sessionOrders.length < upstate.sessionOrders.length) {
          _currentSessionId = upstate.sessionOrders[sessionOrders.length];
          _viewingSessionId = _currentSessionId;
          _nextStoppingPointIndex = 0;
          console.log(
            '### DEBUG_LOG:COMMENT: セッションが増えたので、現在時刻からセッションIDを決定 #########################',
            _currentSessionId,
          );
        } else if (
          // 全てのセッションが終了後でセッション数が変化しない場合、トランスポートが変更されたかを確認
          currentSessionId == null ||
          !operatingSessions[currentSessionId]
        ) {
          console.log(
            '### DEBUG_LOG:COMMENT:全てのセッションが終了後でセッション数が変化していないので、トランスポートが変更されたかを確認する #########################',
          );
          const currentTimeSessionId = calcCurrentSessionId(this.state);
          const currentTimeSessionIndex = sessionOrders[currentTimeSessionId];
          console.log(
            '### DEBUG_LOG:COMMENT: 現在時刻から算出されたセッションID(更新前のoperatingSessionsについて) #########################',
            currentTimeSessionId,
          );
          for (
            let ix = currentTimeSessionIndex;
            ix < sessionOrders.length;
            ++ix
          ) {
            const oid = sessionOrders[ix];
            const os = operatingSessions[oid];
            const upos = upstate.operatingSessions[oid];
            console.log(
              '### DEBUG_LOG:COMMENT: 順番にtransportsの変更が無いか確認する #########################',
              oid,
              os,
              upos,
            );
            if (
              os.transports &&
              upos.transports &&
              !arrayEquals(os.transports, upos.transports)
            ) {
              console.log(
                '### DEBUG_LOG:COMMENT: transportsに変更があったため、currentSessionに設定 #########################',
                oid,
              );
              _currentSessionId = oid;
              _viewingSessionId = _currentSessionId;
              _nextStoppingPointIndex = 0;
              break;
            }
          }
        }

        if (!upstate.operatingSessions[_currentSessionId]) {
          console.log(
            '### DEBUG_LOG:COMMENT: 更新後にcurrentSessionが消えるため、idを現在時刻から算出 #########################',
          );
          _currentSessionId = calcCurrentSessionId(upstate);
          _viewingSessionId = _currentSessionId;
          _nextStoppingPointIndex = 0;
        }

        _operatingSessions = upstate.operatingSessions;
        _sessionOrders = upstate.sessionOrders;
        _passengerNameDict = upstate.passengerNameDict;

        this.setState(
          {
            currentSessionId: _currentSessionId,
            viewingSessionId: _viewingSessionId,
            nextStoppingPointIndex: _nextStoppingPointIndex,
            operatingSessions: _operatingSessions,
            sessionOrders: _sessionOrders,
            passengerNameDict: _passengerNameDict,
            failedUpdateOperatingSessions: false,
          },
          () => {
            console.log(
              `index/_updateOperatingSessions: operatingSessionsを最新に更新しました`,
              this.state,
            );
          },
        );
      });
    }
  }

  _setSelectedStoppingPointIndex(index) {
    console.debug(
      '### DEBUG_LOG:CLASS-Index:FUNCTION:_setSelectedStoppingPointIndex #########################',
    );
    this.setState({
      selectedStoppingPointIndex: index,
    });
  }

  render() {
    const {
      gpsPosition,
      jsGpsPosition,
      sessionStartPosition,
      operatingSessions,
      sessionOrders,
      currentSessionId,
      viewingSessionId,
      nextStoppingPointIndex,
      selectedStoppingPointIndex,
      passengerNameDict,
      isArrived,
      isArrivedOnRoute,
      vehicleData,
      confirm,
    } = this.state;

    const numOfSessions = sessionOrders.length;
    const viewingCurrentSession = currentSessionId === viewingSessionId;
    let currentSessionIndex = sessionOrders.findIndex(
      (oid) => oid === currentSessionId,
    );
    if (currentSessionIndex === -1) {
      currentSessionIndex = numOfSessions;
    }
    let viewingSessionIndex = sessionOrders.findIndex(
      (oid) => oid === viewingSessionId,
    );
    if (viewingSessionIndex === -1) {
      viewingSessionIndex = numOfSessions;
    }
    const previousSessionId =
      currentSessionIndex > 0 ? sessionOrders[currentSessionIndex - 1] : null;

    const previousSession =
      operatingSessions && operatingSessions[previousSessionId]
        ? operatingSessions[previousSessionId]
        : null;
    const currentSession =
      operatingSessions && operatingSessions[currentSessionId]
        ? operatingSessions[currentSessionId]
        : null;
    const viewingSession =
      operatingSessions && operatingSessions[viewingSessionId]
        ? operatingSessions[viewingSessionId]
        : null;
    const currentPath =
      currentSession &&
      0 <= nextStoppingPointIndex &&
      nextStoppingPointIndex < currentSession.stoppingPoints.length
        ? {
            from:
              nextStoppingPointIndex === 0
                ? sessionStartPosition
                : getStopCoordinateFromRoute(
                    currentSession,
                    currentSession.stoppingPoints[nextStoppingPointIndex - 1],
                  ),
            to: getStopCoordinateFromRoute(
              currentSession,
              currentSession.stoppingPoints[nextStoppingPointIndex],
            ),
            via:
              nextStoppingPointIndex === 0
                ? []
                : sliceRouteBetweenStoppingPoints(
                    currentSession,
                    nextStoppingPointIndex - 1,
                    nextStoppingPointIndex,
                  ),
          }
        : {from: null, to: null, via: []};
    const leftPane = (
      <div
        ref={this.transitsContainerRef}
        style={{
          position: 'absolute',
          height: '100%',
          width: '100%',
          overflowY: 'scroll',
          paddingRight: '17px',
          boxSizing: 'content-box',
        }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginLeft: '10px',
          }}>
          {viewingSession ? (
            <TransitGroup
              isInactive={!viewingCurrentSession}
              transits={viewingSession.stoppingPoints}
              currentStoppingPointIndex={
                viewingCurrentSession ? nextStoppingPointIndex - 1 : -1
              }
              setSelectedIndex={this._setSelectedStoppingPointIndex.bind(this)}
              width={160}
            />
          ) : (
            '予約待ち'
          )}
        </div>
      </div>
    );
    const leftPaneBottom = (
      <div>
        <UIButton
          style={{
            width: '160px',
            height: '32px',
            fontSize: '16px',
          }}
          onClick={() => this._updateOperatingSessions(true)}>
          リロード
        </UIButton>
        <UIButton
          style={{width: '160px', height: '32px', fontSize: '16px'}}
          isValid={!viewingCurrentSession}
          onClick={this._changeCurrentSessionToViewing.bind(this)}>
          セッション切り替え
        </UIButton>
        <UIButton
          style={{
            width: '80px',
            height: '32px',
            fontSize: '16px',
            display: 'inline-block',
          }}
          isValid={0 < viewingSessionIndex}
          onClick={this._onPrevSession.bind(this)}>
          前
        </UIButton>
        <UIButton
          style={{
            width: '80px',
            height: '32px',
            fontSize: '16px',
            display: 'inline-block',
          }}
          isValid={viewingSessionIndex < numOfSessions}
          onClick={this._onNextSession.bind(this)}>
          次
        </UIButton>
        <UIButton
          style={{
            width: '160px',
            height: '32px',
            fontSize: '14px',
          }}
          isValid={viewingSessionIndex !== currentSessionIndex}
          onClick={this._onCurrentSession.bind(this)}>
          現在のセッションを表示
        </UIButton>
      </div>
    );
    const rightMap =
      gpsPosition && vehicleData ? (
        <Map width="100%" height="100%" center={gpsPosition} zoom={14}>
          {jsGpsPosition ? (
            <VehicleMarker imagePath={markerIcon} latlng={jsGpsPosition} />
          ) : null}
          <RouteProvider
            from={currentPath.from}
            to={currentPath.to}
            via={currentPath.via}
            useVia={this.state.useVia}>
            {(route) => (
              <ServiceOverlay
                showVehicleAlways={true}
                onChangeArriveState={this._onChangeArriveStateOnRoute.bind(
                  this,
                )}
                gpsPosition={gpsPosition}
                routeCoordinates={route ? route.coordinates : null}
                routeDistances={null}
                totalDistance={route ? route.distance : null}
              />
            )}
          </RouteProvider>
        </Map>
      ) : null;
    const modal = (
      <>
        {IS_DEBUGGING ? (
          <>
            <UIButton
              style={{
                position: 'fixed',
                right: '0px',
                top: '96px',
                width: '140px',
                height: '32px',
                fontSize: '12px',
              }}
              onClick={() => {
                this._saveVehicleData(initialVehicleData(), true).then(
                  (newVehicleData) => {
                    this.setState({vehicleData: newVehicleData});
                  },
                );
              }}>
              vehicleDataを初期化
              <br />(
              {this.isUseGPSSTUB
                ? this.useJSGPS
                  ? 'localStorage'
                  : 'Firebase'
                : 'SharedPreferences'}
              )
            </UIButton>
            <UIButton
              style={{
                position: 'fixed',
                right: '0px',
                top: '64px',
                width: '140px',
                height: '32px',
                fontSize: '16px',
              }}
              onClick={() => {
                if (validLatLng(currentPath.to)) {
                  const coords = {
                    latitude: currentPath.to.latitude,
                    longitude: currentPath.to.longitude,
                  };
                  _updateStubGPS([coords], 100, 80);
                  this.setState({
                    gpsPosition: coords,
                  });
                }
              }}>
              終点へ移動
            </UIButton>
            {this.state.vehicleData ? (
              <UIButton
                style={{
                  position: 'fixed',
                  right: '0px',
                  top: '32px',
                  width: '140px',
                  height: '32px',
                  fontSize: '16px',
                }}
                onClick={() => {
                  const vehicleData = this.state.vehicleData;
                  let vehicleState =
                    vehicleData.vehicleState === 'undefined'
                      ? 'waiting'
                      : vehicleData.vehicleState === 'waiting'
                      ? 'driving'
                      : 'undefined';
                  this._saveVehicleData({
                    vehicleState,
                  }).then((newVehicleData) => {
                    console.log(
                      `index/onArrived: 降車地点: vehicleState <- ${newVehicleData.vehicleState}`,
                      newVehicleData,
                    );
                    this.setState({
                      vehicleData: newVehicleData,
                    });
                  });
                }}>
                {this.state.vehicleData.vehicleState}
              </UIButton>
            ) : null}
            <UIButton
              style={{
                position: 'fixed',
                right: '0px',
                top: '0px',
                width: '140px',
                height: '32px',
                fontSize: '16px',
              }}
              onClick={() =>
                this.setState({
                  useVia: !this.state.useVia,
                })
              }>
              routeを{this.state.useVia ? '使う' : '使わない'}
            </UIButton>
          </>
        ) : null}

        {currentSession && selectedStoppingPointIndex != null ? (
          <PassengerDialog
            key="PassengerDialog"
            transit={currentSession.stoppingPoints[selectedStoppingPointIndex]}
            passengerNameDict={passengerNameDict}
            onClose={() => this._setSelectedStoppingPointIndex(null)}
            onClickChangeButton={() => this._changeNextStoppingPointToViewing()}
            onClickArrivedButton={() => this._markArrivedSelectedStop()}
          />
        ) : null}
        {(isArrived || isArrivedOnRoute) &&
        vehicleData &&
        (vehicleData.vehicleState === 'driving' ||
          // 終点と次のセッションの開始地点が同一地点でvehicleStateが"undefined"の場合
          // 出発地に向かうボタンを余計に押す必要があるため、到着ボタンの表示条件に割り込ませる
          (vehicleData.vehicleState === 'undefined' &&
            nextStoppingPointIndex === 0 &&
            currentSession &&
            previousSession &&
            currentSession.stoppingPoints[nextStoppingPointIndex].location ===
              previousSession.stoppingPoints[
                previousSession.stoppingPoints.length - 1
              ].location) ||
          (vehicleData.vehicleState === 'undefined' &&
            nextStoppingPointIndex > 0)) ? (
          <UIButton
            style={{
              position: 'fixed',
              right: '0',
              bottom: '18px',
              width: '200px',
              height: '64px',
              fontSize: '20px',
            }}
            onClick={() => {
              this.isArrivedButtonPressed = true;
              this.forceUpdate(() => {
                this._onClickArrivedButton();
              });
            }}
            isValid={!this.isArrivedButtonPressed}>
            到着
          </UIButton>
        ) : vehicleData &&
          currentSession &&
          vehicleData.vehicleState === 'waiting' &&
          nextStoppingPointIndex < currentSession.stoppingPoints.length ? (
          <UIButton
            style={{
              position: 'fixed',
              right: '0',
              bottom: '18px',
              width: '200px',
              height: '64px',
              fontSize: '20px',
            }}
            onClick={() => {
              this.isDepartureButtonPressed = true;
              this.forceUpdate(() => {
                this._onClickDepartureButton();
              });
            }}
            isValid={!this.isDepartureButtonPressed}>
            出発
          </UIButton>
        ) : vehicleData &&
          vehicleData.vehicleState === 'undefined' &&
          nextStoppingPointIndex === 0 &&
          // =現在のセッションが無い場合は表示しない
          currentSession ? (
          <UIButton
            style={{
              position: 'fixed',
              right: '0',
              bottom: '18px',
              width: '200px',
              height: '64px',
              fontSize: '20px',
            }}
            onClick={this._onClickGotoDeparturePointButton.bind(this)}>
            出発地に向かう
          </UIButton>
        ) : null}

        {confirm ? (
          <ConfirmDialog
            message={confirm.message}
            acceptLabel={confirm.acceptLabel ? confirm.acceptLabel : 'はい'}
            rejectLabel={confirm.rejectLabel ? confirm.rejectLabel : 'いいえ'}
            onAccept={() => {
              this.setState({confirm: null});
              if (confirm.onAccept) {
                confirm.onAccept();
              }
            }}
            onReject={() => {
              this.setState({confirm: null});
              if (confirm.onReject) {
                confirm.onReject();
              }
            }}
          />
        ) : null}
      </>
    );

    return MainContents({
      leftPane,
      leftPaneBottom,
      rightMap,
      modal,
    });
  }
}

export default Index;
