import React from 'react';

const FillColor = '#ff0000';
const ControlPoints = [0, 5, -20, 5, -20, 15];

function redraw({ctx, angle, width, height, color}) {
  const centerX = width / 2;
  const centerY = height / 2;
  const startX = centerX - centerX * Math.cos(angle);
  const startY = centerY - centerY * Math.sin(angle);
  const endX = centerX + centerX * Math.cos(angle);
  const endY = centerY + centerY * Math.sin(angle);
  const dx = endX - startX;
  const dy = endY - startY;
  const len = Math.sqrt(dx * dx + dy * dy);
  const sin = dy / len;
  const cos = dx / len;
  const a = [];
  a.push(0, 0);
  for (let i = 0; i < ControlPoints.length; i += 2) {
    const x = ControlPoints[i];
    const y = ControlPoints[i + 1];
    a.push(x < 0 ? len + x : x, y);
  }
  a.push(len, 0);
  for (let i = ControlPoints.length; i > 0; i -= 2) {
    const x = ControlPoints[i - 2];
    const y = ControlPoints[i - 1];
    a.push(x < 0 ? len + x : x, -y);
  }
  a.push(0, 0);
  for (let i = 0; i < a.length; i += 2) {
    const x = a[i] * cos - a[i + 1] * sin + startX;
    const y = a[i] * sin + a[i + 1] * cos + startY;
    if (i === 0) ctx.moveTo(x, y);
    else ctx.lineTo(x, y);
  }
  ctx.fillStyle = color ? color : FillColor;
  ctx.fill();
}

const Arrow = ({angle, width, height, color}) => {
  const canvasEl = React.useRef();
  React.useLayoutEffect(() => {
    const ctx = canvasEl.current.getContext('2d');
    redraw({ctx, angle: (angle * Math.PI) / 180, width, height, color});
  }, [angle, width, height, color, canvasEl]);
  return (
    <canvas
      ref={canvasEl}
      width={width}
      height={height}
      style={{width, height, userSelect: 'none'}}
    />
  );
};

export default Arrow;
