import React from 'react';
import UIOverlay from '../atoms/UIOverlay';
import UIButton from '../atoms/UIButton';
import styled from 'styled-components';
import passengerNameDictContext from '../atoms/PassengerNameDictContext';

const Cover = styled.div`
  position: relative;
  top: 0;
  left: 0;
  z-index: 10001;
  min-width: 100vw;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.4));
  pointer-events: auto;
  user-select: none;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 10002;
  position: fixed;
  top: 0;
  left: 160px;
  width: 400px;
  max-width: 100vw;
  max-height: 100vh;
  background-color: #e4f0f5;
  border: 1px solid #3d7e9a;
  pointer-events: auto;
  user-select: none;
  ul {
    li {
      font-size: 18px;
      user-select: none;
    }
  }
`;

const ScrollContainer = styled.div`
  overflow-y: scroll;
  user-select: none;
`;

const PassengerText = styled.p`
  font-size: 24px;
  user-select: none;
`;

const ButtonContainer = styled.div`
  margin-top: auto;
  position: relative;
  bottom: 0;
  display: flex;
  flex-direction: row;
  width: 100%;
  user-select: none;
`;

const CloseButtonStyle = {
  width: '48px',
  height: '48px',
  position: 'absolute',
  top: '0',
  right: '0',
  userSelect: 'none',
};

const ButtonStyle = {
  width: '50%',
  height: '48px',
  userSelect: 'none',
};

export const PassengerDialog = ({
  passengerNameDict,
  transit,
  onClose,
  onClickChangeButton,
  onClickArrivedButton,
}) => {
  return transit ? (
    <UIOverlay
      style={{zIndex: 99999, display: 'block'}}
      key="PassengerDialog-Overlay">
      <Cover onClick={onClose} />
      <Container key="PassengerDialog-Container">
        <UIButton
          style={CloseButtonStyle}
          key="dialog-close-btn"
          onClick={onClose}>
          X
        </UIButton>
        <ScrollContainer>
          <PassengerText key="dropoff-num">
            降車人数 : {Object.keys(transit.dropoffs).length}
          </PassengerText>
          <ul key="dropoff-Passenger">
            {transit.dropoffs.map((dropoff, ix) => {
              return (
                <li key={`dropoff-${dropoff.passenger}-${ix}`}>
                  <span
                    style={{color: passengerNameDict[dropoff.passenger].color}}>
                    {passengerNameDict[dropoff.passenger].name}
                  </span>
                </li>
              );
            })}
          </ul>
          <PassengerText key="pickup-num">
            乗車人数 : {Object.keys(transit.pickups).length}
          </PassengerText>
          <ul key="pickup-Passenger">
            {transit.pickups.map((pickup, ix) => {
              return (
                <li key={`pickup-${pickup.passenger}-${ix}`}>
                  <span
                    style={{color: passengerNameDict[pickup.passenger].color}}>
                    {passengerNameDict[pickup.passenger].name}
                  </span>
                </li>
              );
            })}
          </ul>
        </ScrollContainer>
        <ButtonContainer>
          <UIButton
            style={ButtonStyle}
            key="dialog-close-btn"
            onClick={onClickChangeButton}>
            次の目的地に設定
          </UIButton>
          <UIButton
            style={ButtonStyle}
            key="dialog-close-btn"
            onClick={onClickArrivedButton}>
            到着済み
          </UIButton>
        </ButtonContainer>
      </Container>
    </UIOverlay>
  ) : null;
};
