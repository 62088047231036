import React from 'react';
import {Marker} from 'react-map-gl';
import LocationIcon from '../location.png';

export default class GPSMarker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      opacity: 0.75,
    };
    this.timestamp = Date.now();
    this.animateID = null;
    this.animateTime = Date.now();
  }

  // componentDidMount() {
  //   this.animateID = requestAnimationFrame(this.animate.bind(this));
  // }

  // componentWillUnmount() {
  //   cancelAnimationFrame(this.animateID);
  // }

  // shouldComponentUpdate(nextProps, nextState) {
  //   return (
  //     this.props.latlng !== nextProps.latlng ||
  //     this.state.opacity != nextState.opacity
  //   );
  // }

  // componentDidUpdate(prevProps) {
  //   if (prevProps.latlng !== this.props.latlng) {
  //     this.timestamp = Date.now();
  //   }
  // }

  // animate() {
  //   const now = Date.now();
  //   const deltaTime = now - this.animateTime;
  //   if (deltaTime > 1000 / 60) {
  //     this.animateTime = now;
  //     const opacity = Math.max(
  //       0.75 - Math.pow((now - this.timestamp) / 500, 2),
  //       0.1,
  //     );
  //     this.setState({opacity});
  //   }
  //   this.animateID = requestAnimationFrame(this.animate.bind(this));
  // }

  render() {
    return (
      <Marker
        {...this.props}
        latitude={this.props.latlng.latitude}
        longitude={this.props.latlng.longitude}>
        <img
          src={LocationIcon}
          width={32}
          height={32}
          style={{
            opacity: this.state.opacity,
            transform: `translateX(-50%) translateY(-50%)`,
          }}
        />
      </Marker>
    );
  }
}
