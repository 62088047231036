import React from 'react';
import {HTMLOverlay} from 'react-map-gl';
import RoutePolyline from '../atoms/RoutePolyline';
import VehicleMarker from '../atoms/VehicleMarker';
import GPSMarker from '../atoms/GPSMarker';
import {pointOnPathFromDistance, closestPointOnPath, distance} from '../utils';

const GPS_INTERVAL = 1000; /* msec */
const ARRIVAL_ERROR = 100; /* meter */

function calcRouteDistances(routeCoordinates, total) {
  let totalLL = 0;
  let distsLL = [];
  for (let ix = 0; ix < routeCoordinates.length - 1; ++ix) {
    distsLL[ix] = distance(routeCoordinates[ix], routeCoordinates[ix + 1]);
    totalLL += distsLL[ix];
  }
  return distsLL.map((ll) => total * (ll / totalLL));
}

function coordinateIsValid(coord) {
  return (
    coord &&
    coord.latitude != null &&
    coord.longitude != null &&
    !Number.isNaN(coord.latitude) &&
    !Number.isNaN(coord.longitude)
  );
}

export default class ServiceOverlay extends React.Component {
  constructor(props) {
    super(props);
    const routeDistances = props.routeDistances
      ? props.routeDistances
      : props.routeCoordinates
      ? calcRouteDistances(props.routeCoordinates, props.totalDistance)
      : null;
    this.state = {
      speed: 0,
      gpsDistance: 0,
      predDistance: 0,
      predPosition: {latitude: 0, longitude: 0},
      routeDistances,
    };
    this.animateID = null;
    this.animateTime = Date.now();
  }

  componentDidMount() {
    // this.animateID = requestAnimationFrame(this._animate.bind(this));
  }

  componentWillUnmount() {
    // cancelAnimationFrame(this.animateID);
  }

  shouldComponentUpdate(nextProps, nextState) {
    /* 経路・GPS・予測位置が更新された場合 */
    return (
      this.props.routeCoordinates != nextProps.routeCoordinates ||
      this.props.routeDistances != nextProps.routeDistances ||
      this.props.gpsPosition != nextProps.gpsPosition ||
      this.state.predPosition != nextState.predPosition
    );
  }

  componentDidUpdate(prevProps, prevState) {
    /* GPSが更新された場合、予測位置を修正し、予測移動速度を計算 */
    if (prevProps.gpsPosition !== this.props.gpsPosition) {
      this._updateWithGPS(prevProps, prevState);
    }
    /* 経路が更新された場合、始点を基準に状態更新 */
    if (prevProps.routeCoordinates != this.props.routeCoordinates) {
      console.log('ServiceOverlay: 経路が更新されました');
      this.props.onChangeArriveState(false);
      let routeDistances;
      if (this.props.routeDistances) {
        routeDistances = this.props.routeDistances;
      } else {
        routeDistances = calcRouteDistances(
          this.props.routeCoordinates,
          this.props.totalDistance,
        );
      }
      this.setState({
        speed: 0,
        gpsDistance: 0,
        predDistance: 0,
        routeDistances,
      });
    }
  }

  /* マーカーが経路上の予測位置をスムーズに移動 */
  _animate() {
    const now = Date.now();
    const deltaTime = now - this.animateTime;
    if (deltaTime > 1000 / 10) {
      this.animateTime = now;
      /* 経路が設定されている場合、予測距離を速度に従って更新し、予測位置を計算 */
      if (this.props.routeCoordinates && this.state.routeDistances) {
        const predDistance =
          this.state.predDistance + (this.state.speed * deltaTime) / 10;
        const predPosition = pointOnPathFromDistance(
          this.props.routeCoordinates,
          this.state.routeDistances,
          predDistance,
        );
        /* 予測位置から目的地までの距離がARRIVAL_ERROR以下かつ、GPS距離と予測距離との差がARRIVAL_ERROR以下の場合、到着したと見做す */
        if (
          // this.props.totalDistance - predDistance < ARRIVAL_ERROR &&
          // Math.abs(this.state.gpsDistance - predDistance) < ARRIVAL_ERROR &&
          // NOTE: 実際のGPS位置のみで接近判定
          Math.abs(this.props.totalDistance - this.state.gpsDistance) <
          ARRIVAL_ERROR
        ) {
          this.props.onChangeArriveState(true);
        } else {
          this.props.onChangeArriveState(false);
          this.setState({
            predDistance,
            predPosition: coordinateIsValid(predPosition)
              ? predPosition
              : this.state.predPosition,
          });
        }
      }
    }
    this.animateID = requestAnimationFrame(this._animate.bind(this));
  }

  /* GPS位置に従って予測速度などを更新 */
  _updateWithGPS(prevProps, prevState) {
    let predPosition,
      curPer = 0;
    let speed = this.state.speed;

    // 経路が設定されている場合、GPSから経路上の位置が計算出来る
    if (this.props.routeCoordinates && this.state.routeDistances) {
      // 前回のGPS位置から最短の経路上の点の、始点からの距離を計算
      const [, prevPer] = closestPointOnPath(
        this.props.routeCoordinates,
        this.state.routeDistances,
        [prevProps.gpsPosition.latitude, prevProps.gpsPosition.longitude],
      );
      // 新しいGPS位置から最短の経路上の点の位置と、始点からの距離を計算
      const [_predPosition, _curPer] = closestPointOnPath(
        this.props.routeCoordinates,
        this.state.routeDistances,
        this.props.gpsPosition,
      );
      curPer = _curPer;
      predPosition = _predPosition;
      // 前回のGPS更新時の速度と、今回移動した分の平均速度とする
      speed = (this.state.speed + (curPer - prevPer) / GPS_INTERVAL) / 2;
    }

    this.setState({
      predPosition: predPosition ? predPosition : prevState.predPosition,
      predDistance: predPosition ? curPer : prevState.predDistance,
      gpsDistance: curPer,
      speed,
    });
  }

  _redraw({width, height, project, unproject}) {
    const {
      gpsPosition,
      routeCoordinates,
      showVehicleAlways,
      vehicleImage,
    } = this.props;
    const {predPosition, routeDistances} = this.state;
    const gpsPositionIsValid = coordinateIsValid(gpsPosition);
    const predPositionIsValid = coordinateIsValid(predPosition);
    this.project = ([a, b]) => project([b, a]);
    this.unproject = (pos) => {
      const res = unproject(pos);
      return [res[1], res[0]];
    };
    return (
      <>
        {routeCoordinates ? (
          <RoutePolyline coordinates={routeCoordinates} />
        ) : null}
        {gpsPositionIsValid ? (
          <VehicleMarker imagePath={vehicleImage} latlng={gpsPosition} />
        ) : null}
        {(routeCoordinates || showVehicleAlways) && gpsPositionIsValid ? (
          <VehicleMarker
            opacity={0.25}
            latlng={
              routeCoordinates && routeDistances && predPositionIsValid
                ? predPosition
                : gpsPosition
            }
          />
        ) : null}
      </>
    );
  }

  render() {
    return <HTMLOverlay redraw={this._redraw.bind(this)} />;
  }
}
