/**
 * firebaseの設定情報
 */
export const firebaseConfig = {
    // Production
    /*
    apiKey: "AIzaSyA7On9-XoqVjb85Mpa-xGsZ6cG14BbW9k0",
    authDomain: "oka-ride-mobile-app.firebaseapp.com",
    databaseURL: "https://oka-ride-mobile-app.firebaseio.com",
    projectId: "oka-ride-mobile-app",
    storageBucket: "oka-ride-mobile-app.appspot.com",
    messagingSenderId: "931814004684",
    appId: "1:931814004684:web:00e5620073df5b152daf69",
    measurementId: "G-9XCXZBG4VS"
    */

    // Development
    apiKey: "AIzaSyBjodyWhu4rs0gjPx6f4of1xZaMA7fkjcc",
    authDomain: "oka-ride-mobile-app-dev.firebaseapp.com",
    databaseURL: "https://oka-ride-mobile-app-dev.firebaseio.com",
    projectId: "oka-ride-mobile-app-dev",
    storageBucket: "oka-ride-mobile-app-dev.appspot.com",
    messagingSenderId: "1094066533105",
    appId: "1:1094066533105:web:7017d331b93d91654caf50",
    measurementId: "G-5TCPV5H2CM"
};
