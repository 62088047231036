import promiseRetry from "promise-retry";

const RETRIES = 10;
const INTERVAL = 3000;

/**
 * fetch が成功するまでリトライする
 *
 * fetch の成功判定は test {(response) => boolean} で行う
 *
 * @param {string} url
 * @param {RequestInit?} init - fetch param
 * @param {function?} test - response を成功と扱う場合に true を返す
 * @return {Promise<Response>}
 */
export function fetchRetry(url, init, test = (res) => true) {
  return promiseRetry((retry, num) => {
      console.log(`[${num}] (re)try...`);

      return fetch(url, init)
        .then(res => {
          console.log(`[${num}] fetch completed. status: ${res.status}`);
          if (!test(res)) {
            console.log(`[${num}] bad response.`);
            return retry(res);
          }
          return res;
        })
        .catch(e => {
          console.log(`[${num}] fetch fail.`);
          console.log(`[${num}] ${e.message}`);
          return retry(e);
        });
    },
    {retries: RETRIES, minTimeout: INTERVAL, maxTimeout: INTERVAL})
    .catch(e => {
      console.error("[xx] retry failed.");
      console.error(e);
      throw e;
    });
}