import React from 'react';

export function arrayEquals(a, b) {
  return JSON.stringify(a) === JSON.stringify(b);
}

export function distance2(a, b) {
  return (a[0] - b[0]) * (a[0] - b[0]) + (a[1] - b[1]) * (a[1] - b[1]);
}

export function angle2(a, b) {
  return Math.atan2(b[1] - a[1], b[0] - a[0]);
}

export function pldistance2([x0, y0], [x1, y1], [x2, y2]) {
  const a = x2 - x1;
  const b = y2 - y1;
  const a2 = a * a;
  const b2 = b * b;
  const r2 = a2 + b2;
  const tt = -(a * (x1 - x0) + b * (y1 - y0));
  if (tt < 0) {
    return (x1 - x0) * (x1 - x0) + (y1 - y0) * (y1 - y0);
  }
  if (tt > r2) {
    return (x2 - x0) * (x2 - x0) + (y2 - y0) * (y2 - y0);
  }
  const f1 = a * (y1 - y0) - b * (x1 - x0);
  return (f1 * f1) / r2;
}

// closestPointOnLine(座標P, 座標A, 座標B) -> [[X, Y], per]
// Ref: https://gist.github.com/larsberg/8561823
// Ref: http://www.peroxide.dk/download/tutorials/tut10/source/vectormath.cpp
export function closestPointOnLine([x0, y0], [x1, y1], [x2, y2]) {
  const c = [x0 - x1, y0 - y1];
  let V = [x2 - x1, y2 - y1];

  const d = Math.sqrt(V[0] * V[0] + V[1] * V[1]);

  V = [V[0] / d, V[1] / d];
  const t = V[0] * c[0] + V[1] * c[1];
  // console.log(t, d)

  if (t < 0) return [[x1, y1], 0];
  if (t > d) return [[x2, y2], 1];

  const [x, y] = [x1 + V[0] * t, y1 * V[1] * t];
  const len = Math.sqrt((x - x1) * (x - x1) + (y - y1) * (y - y1));

  return [[x, y], len / d];
}

export function closestPointOnPath(path, distances, currentPosition) {
  let [dmin, p, dist] = [Infinity, null, 0];
  let accumDistance = 0;
  let currentDistance = 0;
  const precision = 10;

  for (let j = 1; j < path.length; j++) {
    accumDistance += distances[j - 1];
    while (accumDistance >= currentDistance) {
      let per = (accumDistance - currentDistance) / distances[j - 1];
      let _p = {
        latitude: path[j - 1].latitude * per + path[j].latitude * (1 - per),
        longitude: path[j - 1].longitude * per + path[j].longitude * (1 - per),
      };
      let d = distance(currentPosition, _p);
      if (dmin > d) {
        dmin = d;
        p = _p;
        dist = currentDistance;
      }
      currentDistance += precision;
    }
  }

  return [p, dist];
}

export function pointOnPathFromDistance(path, distances, currentDistance) {
  let accumDistance = 0;
  for (let j = 1; j < path.length; j++) {
    accumDistance += distances[j - 1];
    if (accumDistance < currentDistance) continue;
    const per = (accumDistance - currentDistance) / distances[j - 1];
    const result = {
      latitude: path[j - 1].latitude * per + path[j].latitude * (1 - per),
      longitude: path[j - 1].longitude * per + path[j].longitude * (1 - per),
    };
    if (isNaN(result.latitude) || isNaN(result.longitude)) {
      break;
    } else {
      return result;
    }
  }
  return {latitude: NaN, longitude: NaN};
}

export function latlngToPoint(latlng) {
  return [latlng.latitude, latlng.longitude];
}

export function pointToLatlng(point) {
  return {latitude: point[0], longitude: point[1]};
}

export const useAnimationFrame = (callback) => {
  // Use useRef for mutable variables that we want to persist
  // without triggering a re-render on their change
  const requestRef = React.useRef();
  const previousTimeRef = React.useRef();

  const animate = (time) => {
    if (previousTimeRef.current !== undefined) {
      const deltaTime = time - previousTimeRef.current;
      callback(deltaTime);
    }
    previousTimeRef.current = time;
    requestRef.current = requestAnimationFrame(animate);
  };

  React.useEffect(() => {
    requestRef.current = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(requestRef.current);
  }, []); // Make sure the effect runs only once
};

export function distance(a, b) {
  return (
    (a.latitude - b.latitude) * (a.latitude - b.latitude) +
    (a.longitude - b.longitude) * (a.longitude - b.longitude)
  );
}

export function StrtoBoolean(data) {
  console.log(data);
  if (data.toLowerCase() === 'true') {
    return true;
  } else {
    return false;
  }
}

const concat = (x, y) => x.concat(y);

export const flatMap = (f, xs) => xs.map(f).reduce(concat, []);

export function latlngEquals(lhs, rhs) {
  if (lhs === rhs) return true;
  if (lhs == null || rhs == null) return false;
  return lhs.latitude !== rhs.latitude || lhs.longitude !== rhs.longitude;
}

export function getDayTimeRange(today = null) {
  if (today == null) {
    today = new Date();
  }
  today.setHours(0, 0, 0, 0);
  const start = today.getTime();
  today.setHours(23, 59, 59, 999);
  const end = today.getTime();
  return [start, end];
}
