import firebaseApp from "./firebase-app";
import "firebase/firestore";


export const getPassengerFromFireStore = async (uid) => {
    const fetchedData = await firebaseApp
      .firestore()
      .collection("users")
      .doc(uid)
      .get();
    console.log("INFO Passenger data fetched.");
    let passengerData = fetchedData.data();
    return passengerData;
};