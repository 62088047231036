import React, {useState} from 'react';
import NamePlate from '../atoms/NamePlate';
import Arrow from '../atoms/Arrow';
import styled from 'styled-components';

const Container = styled.div`
  position: relative;
  width: ${(props) => props.width || 160}px;
  height: ${(props) => props.height || 100}px;
  font-size: 12px;
  user-select: none;
`;

const Start = styled.div`
  position: absolute;
  top: 0px;
  text-align: right;
  width: ${(props) => props.width || 160}px;
  user-select: none;
`;

const Middle = styled.div`
  position: absolute;
  right: 90px;
  top: 30px;
  user-select: none;
`;

const End = styled.div`
  position: absolute;
  bottom: 0px;
  width: ${(props) => props.width || 160}px;
  user-select: none;
`;

const FlowContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  user-select: none;
`;

function timeToString(t) {
  return `${t.getHours()}時${t.getMinutes()}分`;
}

const TransitGroup = ({
  transits,
  currentStoppingPointIndex,
  width,
  setSelectedIndex,
  isInactive,
}) => {
  const components = [];
  if (transits.length === 0) return components;
  const lastIdx = transits.length - 1;
  const last = transits[lastIdx];
  const front = transits.slice(0, -1);
  const nextStoppingPointIndex = currentStoppingPointIndex + 1;

  const lastIsCurrent = lastIdx === currentStoppingPointIndex;
  const lastIsNext = lastIdx === nextStoppingPointIndex;
  const lastColor = lastIsCurrent
    ? '#00c8fc'
    : lastIsNext
    ? '#00fcc8'
    : '#fcc800';
  return (
    <>
      {front.map((transit, key) => {
        const time = transit.transitTime
          .split('~')
          .map((s) => timeToString(new Date(parseInt(s) * 1000)));
        const isCurrent = key === currentStoppingPointIndex;
        const isNext = key === nextStoppingPointIndex;
        const color = isCurrent ? '#00c8fc' : isNext ? '#00fcc8' : '#fcc800';
        return (
          <FlowContainer key={key}>
            <NamePlate
              key={`np-${key}${isCurrent ? '-current' : isNext ? '-next' : ``}`}
              name={transit.location}
              width={`${width - 16}px`}
              fontSize={`${width / 8}px`}
              color={color}
              isValid={!isInactive}
              click={() => {
                setSelectedIndex(key);
              }}
            />
            <Container key={`ctn-${key}`} width={width} height={80}>
              <Start key={`s-${key}`}>{time[0]}発</Start>
              <Middle key={`m-${key}`}>{transit.numberOfPassengers}名</Middle>
              <Arrow
                key={`a-${key}`}
                angle={90}
                width={width}
                height={80}
                color={isInactive ? '#ccc' : '#ff0000'}
              />
            </Container>
          </FlowContainer>
        );
      })}
      <NamePlate
        key={`np-${lastIdx}${
          lastIsCurrent ? '-current' : lastIsNext ? '-next' : ''
        }`}
        name={last.location}
        width={`${width - 16}px`}
        fontSize={`${width / 8}px`}
        isValid={!isInactive}
        color={lastColor}
        click={() => {
          setSelectedIndex(lastIdx);
        }}
      />
    </>
  );
};

export default TransitGroup;
