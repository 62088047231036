import React from 'react';
import styled from 'styled-components';

const UIButonOuter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  pointer-events: auto;
  background: #000;
  cursor: pointer;

  &:hover {
    background: #000;
    color: #fff;
    transition: all 0.3s;
  }
`;

const UIButonInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  background: #3db1ca;
  color: #000;
  user-select: none;
  &:hover {
    background: #6fe9ff;
    transition: all 0.1s;
  }
  &:active {
    background: #0d819a;
    transition: none;
  }
`;

const UIButonInvalidOuter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  pointer-events: auto;
  background: #000;
`;

const UIButonInvalidInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  background: #666;
  color: #333;
  user-select: none;
`;

const UIButon = ({style, onClick, isValid = true, children}) => {
  return isValid ? (
    <UIButonOuter style={style} onClick={onClick}>
      <UIButonInner>{children}</UIButonInner>
    </UIButonOuter>
  ) : (
    <UIButonInvalidOuter style={style}>
      <UIButonInvalidInner>{children}</UIButonInvalidInner>
    </UIButonInvalidOuter>
  );
};

export default UIButon;
