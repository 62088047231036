import React from 'react';
import ReactMapGL, {LinearInterpolator, FlyToInterpolator} from 'react-map-gl';

const Map = (props) => {
  const {width, height, center, zoom, onViewportChange} = props;
  const [viewport, setViewport] = React.useState({
    width,
    height,
    latitude: center.latitude,
    longitude: center.longitude,
    zoom: 14,
    transitionDuration: 1000,
    transitionInterpolator: new FlyToInterpolator(),
  });
  React.useEffect(() => {
    if (
      center.latitude != null &&
      center.longitude != null &&
      !Number.isNaN(center.latitude) &&
      !Number.isNaN(center.longitude)
    ) {
      setViewport({
        ...viewport,
        latitude: center.latitude,
        longitude: center.longitude,
      });
    }
  }, [center]);
  return (
    <ReactMapGL
      {...viewport}
      onViewportChange={(viewport) => setViewport(viewport)}
      mapStyle="mapbox://styles/nortkave/ck65sh0mt2v001jmau4e1znws"
      // mapStyle={{
      //   version: 8,
      //   sources: {
      //     OSM: {
      //       type: 'raster',
      //       tiles: ['http://a.tile.openstreetmap.org/{z}/{x}/{y}.png'],
      //       tileSize: 256,
      //     },
      //   },
      //   layers: [
      //     {
      //       id: 'OSM',
      //       type: 'raster',
      //       source: 'OSM',
      //       minzoom: 0,
      //       maxzoom: 22,
      //     },
      //   ],
      // }}
    >
      {props.children}
    </ReactMapGL>
  );
};

export default Map;
