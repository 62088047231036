import {_STUB_VEHICLE} from './_vehicle.api';
import {pointOnPathFromDistance} from './utils';
import {fetchRetry} from "./util/fetch";

const OTP_URL = 'http://18.176.220.191:8080';

const VEHICLE_URL = 'http://18.176.220.191:7000/vehicles';

const PASSENGER_URL = 'http://18.176.220.191:7000/passengers';

// TODO: WEB走行ルート
// TODO: WebView組み込み後にGPSどうするか

export async function fetchPassenger(id) {
  const url = PASSENGER_URL + `/${id}`;
  console.log(url);
  return fetchRetry(url, {method: 'GET'})
    .then((res) => res.json());
}

export async function fetchVehicle(id) {
  const url = VEHICLE_URL + `/${id}`;
  console.log(url);
  return fetchRetry(url, {method: 'GET'})
    .then((res) => res.json());
}

// stub
export async function $fetchVehicle(id, time = null) {
  let stub = {..._STUB_VEHICLE};
  stub.data.name = id;
  if (time != null) {
    const baseTime = stub.data.operatingTimes[0]
      .split('~')
      .map((t) => new Date(parseFloat(t) * 1000))[0];
    const timeReplacer = (src) => {
      const newTime = src
        .split('~')
        .map((t) => time + (new Date(parseFloat(t) * 1000) - baseTime));
      return `${newTime[0]}~${newTime[1]}`;
    };
    stub.data.operatingTimes = stub.data.operatingTimes.map(timeReplacer);
    for (let ios = 0; ios < stub.data.operatingSessions.length; ++ios) {
      const os = stub.data.operatingSessions[ios];
      for (let isp = 0; isp < os.stoppingPoints.length; ++isp) {
        const sp = os.stoppingPoints[isp];
        stub.data.operatingSessions[ios].stoppingPoints[
          isp
        ].transitTime = timeReplacer(sp.transitTime);
      }
    }
  }
  return Promise.resolve(stub);
}

export async function searchRouteByOTP(from, to) {
  const url =
    OTP_URL +
    `/otp/routers/default/plan?fromPlace=${from.latitude}%2C${from.longitude}&toPlace=${to.latitude}%2C${to.longitude}&date=2018-01-01&time=00%3A00&mode=CAR`;
  return fetchRetry(url, {method: 'GET'})
    .then((res) => res.json());
}

export async function setVehicleState(id, appData) {
  const url = VEHICLE_URL + `/${id}`;
  console.log('setVehicleState test');
  console.log(JSON.stringify(appData));
  return fetchRetry(url, {
    method: 'PUT',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify(appData),
  })
    .then((response) => {
      console.log(response);
      return response.json();
    });
}

// stub generator
function* _genStubGPS(route, totalDistance, speedKmph) {
  let updated_at = Date.now();
  let totalLL = 0;
  let distsLL = [];
  for (let ix = 0; ix < route.length - 1; ++ix) {
    distsLL[ix] =
      Math.pow(route[ix].latitude - route[ix + 1].latitude, 2) +
      Math.pow(route[ix].longitude - route[ix + 1].longitude, 2);
    totalLL += distsLL[ix];
  }
  const distances = distsLL.map((ll) => totalDistance * (ll / totalLL));
  let current = 0;
  while (current < totalDistance) {
    const pos = pointOnPathFromDistance(route, distances, current);
    yield {coords: pos};
    const now = Date.now();
    const delta = now - updated_at;
    current += ((speedKmph * 1000) / (60 * 60 * 1000)) * delta;
    updated_at = now;
  }
  while (true) {
    yield {coords: route[route.length - 1]};
  }
}

const LATLNG_OKINAWA = {latitude: 26.334353, longitude: 127.805606};
let _currentStubGPS = _genStubGPS([LATLNG_OKINAWA, LATLNG_OKINAWA], 100, 40);

export function _updateStubGPS(route, totalDistance, speedKmph) {
  _currentStubGPS = _genStubGPS(route, totalDistance, speedKmph);
  console.log('stub has updated');
}

export function $gps() {
  return _currentStubGPS;
}
