import React, {PureComponent} from 'react';
import {CanvasOverlay} from 'react-map-gl';

export default class PolylineOverlay extends PureComponent {
  _redraw({width, height, ctx, isDragging, project, unproject}) {
    const {
      coordinates,
      color = 'red',
      lineWidth = 2,
      renderWhileDragging = true,
    } = this.props;
    ctx.clearRect(0, 0, width, height);
    ctx.globalCompositeOperation = 'lighter';

    if ((renderWhileDragging || !isDragging) && coordinates) {
      ctx.lineWidth = lineWidth;
      ctx.strokeStyle = color;
      ctx.beginPath();
      coordinates.forEach((coord) => {
        const pixel = project([coord.longitude, coord.latitude]);
        ctx.lineTo(pixel[0], pixel[1]);
      });
      ctx.stroke();
    }
  }

  render() {
    return <CanvasOverlay redraw={this._redraw.bind(this)} />;
  }
}
